import './areas.scss';

const areas = [
  'San Francisco',
  'Palo Alto',
  'San Jose',
  'Mountain View',
  'Sunnyvale',
  'Santa Clara',
  'Menlo Park',
  'San Mateo',
  'Redwood City',
  'Fremont',
  'Oakland',
  'Berkeley',
  'Cupertino',
  'Pleasanton',
  'Campbell',
  'Los Altos',
  'Burlingame',
  'Milpitas',
  'Emeryville',
  'Los Gatos',
  'South San Francisco',
  'San Ramon',
  'San Carlos',
  'Foster City',
  'San Rafael',
  'San Bruno',
  'Walnut Creek',
  'Santa Cruz',
  'Hayward',
  'Alameda',
  'Sausalito',
  'Santa Rosa',
  'Bodega Bay',
  'Bodega',
  'Glen Ellen',
  'Jenner',
  'Cloverdale',
  'Cazadero',
  'Calistoga',
  'Monte Rio',
  'Windsor',
  'Valley Ford',
  'Rio Del Mar',
  'Pinole',
  'Forest Knolls',
];

const Areas = () => (
  <section className="areas">
    <div className="container">
      <h2 className="section-title white">
        <span>Areas</span> We Serve
      </h2>

      <ul>
        {areas.map((el, index) => (
          <li key={index}>{el}</li>
        ))}
      </ul>
    </div>
  </section>
);

export default Areas;
