export const local_moving = [
  {
    resource: 'Google',
    customer_name: 'Andrew J.',
    date: '2/15/2022',
    text: `I moved after a year from San Francisco to Oakland and used Smart People Moving and again, I highly recommend them. When I booked them they were super-responsive over phone, text, and email. On the day of, the movers were prompt and highly professional. They put all of my valuables in tape to protect them from scratches. They also let me use wardrobe boxes as well as other boxes. This move was complicated because it involved the first stop at my new place of residence and the second or last stop at a storage unit. The movers were very responsive and easy to deal with in following instructions. Because I was a returning customer, Smart People Moving did not double the driving time and gave me free boxes. Yay for Smart People!`,
  },
  {
    resource: 'Facebook',
    customer_name: 'Adriana F.',
    date: '3/17/2022',
    text: `I highly recommend working with Aleks and his crew! I've used Smart People Moving for my last couple of moves in San Francisco and to Marin County. They have done an amazing job both times. Aleks is so responsive and transparent about pricing, logistics, and what to expect the day of the move. They've always arrived promptly and are ready to get the job done. I've never had any of my furniture, boxes, valuables, etc. left with any scratches. They're always careful and do a great job wrapping your belongings.  I couldn't imagine moving without their help. If you ever need a mover in the Bay Area, you should definitely use Smart People Moving!`,
  },
  {
    resource: 'Yelp',
    customer_name: 'Nikole W.',
    date: '6/13/2022',
    text: `We booked Smart People Moving for a last-minute and unexpected move. They showed up right on time the morning of and were so efficient and quick that we finished the move in less time than expected. They handled everything with care and there were no damages. With all this being said, it is hard to believe they were, also, the most affordable option we found for moving companies in SF. 10/10 definitely recommend it.`,
  },
  {
    resource: 'Google',
    customer_name: 'Patricia S.',
    date: '9/2/2022',
    text: `Outstanding experience! They did a fantastic job of moving my house in the rain over the weekend. I really appreciate the way they handled the move of a 100-year-old antique bed. They didn’t have the tools to disassemble the bed on the day of the move. They searched for the appropriate tools and moved the bed after a couple of days. They were patient and understanding throughout the process.`,
  },
];

export const long_moving = [
  {
    resource: 'Google',
    customer_name: 'Jim G.',
    date: '3/31/2022',
    text: `SPM just finished a move for us from San Francisco to Saint Paul, MN.  Everything about the move was impressive. The commitment was 3-4 days from end to end and they nailed it. They arrived when they said they would.  The teams on both ends were enthusiastic, respectful and careful with our furniture. The driver (Wismar) was awesome and took great care of our stuff.  We have moved a few times and this was absolutely our best experience.`,
  },
  {
    resource: 'Facebook',
    customer_name: 'Kristen B.',
    date: '9/9/2022',
    text: `We just moved from San Francisco to the Boston area. Aleks and his crew are fantastic. Aleks gave me the best quote out of several I received from the bay area. I thought it was too good to be true, but he delivered on every promise. We opted for full packing and loading services, and his crew did an impressive job. Everyone worked hard, was in good spirits, and was very respectful. They put a lot of thought into how things were packed and labeled. We even had a piano in our 3-story walkup, and not one person complained. Aleks arrived with our belongings after only 4 days!!! The local crew on the receiving end was fantastic as well. I've done a lot of moves before, as we were in the military. This was our first DIY civilian move. Thank you, Smart People Moving, for taking the stress out of our move and doing a phenomenal job. I've already recommended the company to friends who are moving soon.`,
  },
  {
    resource: 'Yelp',
    customer_name: 'Alex A.',
    date: '4/12/2022',
    text: `Highly recommended! I moved from 2 bedroom apartment in San Francisco to another farther out in the state. These guys were quick to reply and very responsive ahead of the move, and conveniently their quote included labor and materials in the same rate. On the day of the move, my crew of four guys arrived on time at 9 am and were courteous and worked solidly packing my entire apartment up and loading it in their truck. They finished packing around 2 pm and only then went for lunch for 30 minutes, then met me at my new place to unload everything. They wrapped up around 6 pm, so a solid day's work, and the price was more than fair. It was a pleasure moving with this company and so greatly appreciate their services! Highly recommend. Thanks, guys!`,
  },
  {
    resource: 'Google',
    customer_name: 'Simran K.',
    date: '2/8/2022',
    text: `Very professional and super friendly people. We hired them for our long-distance move from San Francisco to Seattle and can't recommend them enough. 3 guys helped us and reached our place right on time. They were very cautious with our stuff, put extra attention into packing our furniture, and welcomed instructions. I would like to give a shoutout to the owner Aleks who was friendly and responded to our questions promptly. They wrapped up the move from SF to Seattle in a day !!!! Thanks so much, team smart people. :)`,
  },
];
