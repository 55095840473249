import Slider from 'react-slick';

import './reviews.scss';

import { long_moving, local_moving } from '../../static/reviews';

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const Reviews = ({ id }) => {
  const reviews = id === 'long-distance-moving' ? long_moving : local_moving;

  return (
    <section className="reviews">
      <div className="container">
        <h2 className="section-title">
          <span>What our clients</span>
          <br />
          have to say
        </h2>

        <Slider className="reviews__list" {...sliderSettings}>
          {reviews?.map((el, index) => (
            <ReviewCard {...{ review: el, key: index }} />
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Reviews;

const ReviewCard = ({ review }) => {
  let icon;

  switch (review.resource) {
    case 'Facebook':
      icon = '/assets/images/logos/facebook_white.svg';
      break;
    case 'Yelp':
      icon = '/assets/images/logos/yelp_white.svg';
      break;
    default:
      icon = '/assets/images/logos/google_white.svg';
      break;
  }
  return (
    <div className="reviews__list--card">
      <div className="reviews__list--card__header">
        <img src={icon} alt={review.resource} />
      </div>
      <div className="reviews__list--card__stars">
        <img src="/assets/images/icons/star_gradient.svg" alt="star icon" />
        <img src="/assets/images/icons/star_gradient.svg" alt="star icon" />
        <img src="/assets/images/icons/star_gradient.svg" alt="star icon" />
        <img src="/assets/images/icons/star_gradient.svg" alt="star icon" />
        <img src="/assets/images/icons/star_gradient.svg" alt="star icon" />
      </div>
      <div className="reviews__list--card__body">
        <p className="reviews__list--card__name">{review.customer_name}</p>
        <p className="reviews__list--card__date">{review.date}</p>
        <p className="reviews__list--card__text">{review.text}</p>
      </div>
    </div>
  );
};
