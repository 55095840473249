import './whyWe.scss';

const reasons = [
  {
    icon: '/assets/images/icons/on-time-delivery.svg',
    title: 'On Time Delivery',
  },
  {
    icon: '/assets/images/icons/fully-licensed-and-insured.svg',
    title: 'Fully Licensed and Insured',
  },
  {
    icon: '/assets/images/icons/equipped-trucks-and-staff.svg',
    title: 'Equipped Trucks & Staff',
  },
  {
    icon: '/assets/images/icons/transparent-pricing.svg',
    title: 'Transparent Pricing',
  },
];

const WhyWe = () => (
  <section className="why-we">
    <div className="container">
      <h2 className="section-title">
        Why Smart
        <br />
        People <span>Moving?</span>
      </h2>

      <div className="why-we__reasons">
        {reasons.map((el, index) => (
          <ReasonCard {...{ el, key: index }} />
        ))}
      </div>
    </div>
  </section>
);

export default WhyWe;

const ReasonCard = ({ el }) => (
  <div className="why-we__reasons--item">
    <div className="why-we__reasons--item__icon">
      <img src={el.icon} alt={el.title} />
    </div>
    <p>{el.title}</p>
  </div>
);
