import { Link, NavLink } from 'react-router-dom';

import { contacts } from '../../static/contacts';

import './footer.scss';

const Footer = () => {
  return (
    <footer className="page-footer">
      <div className="container">
        <div className="page-footer__content">
          <Link to="/" className="page-footer__logo">
            <img src="/assets/images/logo_footer.svg" alt="SMP Logo" />
          </Link>

          <p>We will take care of your belongings and will deliver them safely to the required destination.</p>

          <p>Unlock professional moving service & packing service with Smart People Moving!</p>
        </div>

        <div className="page-footer__contacts">
          <a href={`tel:${contacts.phone}`} className="page-footer__contacts--phone">
            {contacts.phone}
          </a>

          <a href={`mailto:${contacts.email}`} className="page-footer__contacts--email">
            {contacts.email}
          </a>

          <p className="page-footer__contacts--address">{contacts.address}</p>
        </div>
      </div>
      <Underline />
    </footer>
  );
};

export default Footer;

const Underline = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="page-footer__underline">
      <div className="container">
        <div>
          <span>©{currentYear} Smart People Moving. All Rights Reserved.</span>

          <div className="page-footer__underline--links">
            <NavLink to="/privacy-policy">Privacy Policy</NavLink>

            <NavLink to="/accessibility-statement">Accessibility Statement</NavLink>

            <NavLink to="/terms-of-use">Terms of Use</NavLink>
          </div>
        </div>
        <a href="https://toporin.com" target="_blank" rel="noReferrer">
          Designed by <strong>Toporin Studio®</strong> in California
        </a>
      </div>
    </div>
  );
};
