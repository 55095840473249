import { contacts } from '../../static/contacts';

import './contactUs.scss';

const ContactUs = () => (
  <section className="contact-us">
    <div className="container">
      <div className="contact-us__inner">
        <h2 className="section-title white">
          Contact us for your FREE
          <br />
          in-home or virtual quote
        </h2>

        <p>
          Trust Smart People Moving to move your valuables
          <br />
          all the way around the globe!
        </p>

        <div className="contact-us__links">
          <a href={`tel:${contacts.phone}`} className="gradient-btn">
            {contacts.phone}
          </a>
          <span>Call us now!</span>
        </div>

        <div className="contact-us__payments">
          <img src="/assets/images/logos/mastercard.svg" alt="mastercard" />
          <img src="/assets/images/logos/visa.svg" alt="visa" />
          <img src="/assets/images/logos/discover.svg" alt="discover" />
          <img src="/assets/images/logos/ae.svg" alt="eae" />
        </div>
      </div>
    </div>
  </section>
);

export default ContactUs;
