import { NavLink } from 'react-router-dom';

import { contacts } from '../../static/contacts';

import './bookNow.scss';

const BookNow = () => (
  <section className="book-now">
    <div className="container">
      <div className="book-now__inner">
        <h2 className="section-title white">
          Book your move
          <br />
          online today!
        </h2>

        <div className="book-now__links">
          <NavLink
            to="/book-now"
            className="gradient-btn"
            state={{ prevLocation: window.location.pathname.split('/')[1] }}
          >
            Book a Service
          </NavLink>

          <span>
            or call us <a href={`tel:${contacts.phone}`}>{contacts.phone}</a>
          </span>
        </div>
      </div>
    </div>
  </section>
);

export default BookNow;
