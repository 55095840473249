import './areas.scss';

const AreasLongDistance = () => (
  <section className="areas">
    <div className="container">
      <h2 className="section-title white">
        <span>Areas</span> We Serve
      </h2>

      <div className="areas__map">
        <img src="/assets/images/long-distance-moving.svg" alt="map" />
      </div>
    </div>
  </section>
);

export default AreasLongDistance;
