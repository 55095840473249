import './counter.scss';

const items = [
  {
    icon: '/assets/images/counter/clients.svg',
    count: 8042,
    title: 'Clients',
  },
  {
    icon: '/assets/images/counter/miles.svg',
    count: 10932,
    title: 'Miles for year',
  },
  {
    icon: '/assets/images/counter/tons.svg',
    count: 5721,
    title: 'Tons of goods',
  },
];

const Counter = () => (
  <section className="counter">
    <div className="container">
      {items.map((el, index) => (
        <CounterCard {...{ el, key: index }} />
      ))}
    </div>
  </section>
);

export default Counter;

const CounterCard = ({ el }) => (
  <div className="counter__card">
    <div className="counter__card--image">
      <img src={el.icon} alt={el.title} />
    </div>
    <div className="counter__card--text">
      <p>{el.count}</p>
      <span>{el.title}</span>
    </div>
  </div>
);
