import './movingEstimate.scss';

import { contacts } from '../../static/contacts';

const MovingEstimate = () => (
  <section className="moving-estimate">
    <div className="container">
      <div className="moving-estimate__inner">
        <h2 className="section-title white">
          Get a free moving
          <br />
          estimate today!
        </h2>

        <a href={`tel:${contacts.phone}`} className="gradient-btn phone">
          {contacts.phone}
        </a>
      </div>
    </div>
  </section>
);

export default MovingEstimate;
