import { BookingForm } from '../../components';

import './hero.scss';

const banners = [
  {
    title: 'Google Rating',
    reviews: 137,
    icon: '/assets/images/logos/google_colored.svg',
    url: 'https://www.google.com/search?ved=1t:65428&authuser=7&authuser=7&_ga=2.93270173.1816[…]udocid=16148861792506273047&lsig=AB86z5VmEdm7xOuvXLCbKIrGuMjG',
  },
  {
    title: 'Thumbtack Rating',
    reviews: 416,
    icon: '/assets/images/logos/thumbtrack.svg',
    url: 'https://www.thumbtack.com/ca/san-francisco/movers/smart-people-moving/service/383703288753430540',
  },
  {
    title: 'Check out or',
    reviews: 140,
    icon: '/assets/images/logos/yelp_colored.svg',
    url: 'https://www.yelp.com/biz/smart-people-moving-san-francisco',
  },
];

const Hero = (props) => (
  <section className="hero">
    <div className="hero__mobile-image">
      <h1>
        Rated #1
        <br />
        {props.id === 'long-distance-moving' && 'Long-Distance Moving Services'}
        {props.id !== 'long-distance-moving' && 'Local Moving Services'}
        <br />
        in San Francisco
      </h1>
      <p>Make an easy move with SMART PEOPLE MOVING</p>
    </div>

    <div className="container">
      <div className="hero__content">
        <h1>
          Rated #1
          <br />
          {props.id === 'long-distance-moving' && 'Long-Distance Moving Services'}
          {props.id !== 'long-distance-moving' && 'Local Moving Services'}
          <br />
          in San Francisco
        </h1>

        <p>Make an easy move with SMART PEOPLE MOVING</p>

        <Banners />
      </div>

      <div className="hero__form">
        <h2>Get Your Quote Online!</h2>

        <BookingForm id={props.id} />
      </div>
    </div>
  </section>
);

export default Hero;

const Banners = () => (
  <div className="hero__banners">
    {banners.map((el, index) => (
      <a href={el.url} rel="noReferrer" target="_blank" className="hero__banners--item" key={index}>
        <div className="hero__banners--item__icon">
          <img src={el.icon} alt={el.title} />
        </div>
        <div className="hero__banners--item__description">
          <span>{el.title}</span>
          <div className="hero__banners--item__stars">
            <img src="/assets/images/icons/star_red.svg" alt="Red Start Icon" />
            <img src="/assets/images/icons/star_red.svg" alt="Red Start Icon" />
            <img src="/assets/images/icons/star_red.svg" alt="Red Start Icon" />
            <img src="/assets/images/icons/star_red.svg" alt="Red Start Icon" />
            <img src="/assets/images/icons/star_red.svg" alt="Red Start Icon" />
          </div>
          <strong>{el.reviews} reviews</strong>
        </div>
      </a>
    ))}
  </div>
);
