import React from 'react';

import { Layout, Seo } from '../components';

import {
  Hero,
  WhyWe,
  MovingEstimate,
  About,
  Services,
  Pricing,
  BookNow,
  Counter,
  Areas,
  AreasLongDistance,
  Reviews,
  ContactUs,
} from '../sections';

const local_seo = {
  title: 'Local Moving',
  description: '',
};

const long_distance_seo = {
  title: 'Long Distance Moving',
  description: '',
};

const PageTemplate = () => {
  const location = window.location.pathname.split('/')[1];

  return (
    <Layout>
      <Seo data={location === 'long-distance-moving' ? long_distance_seo : local_seo} />
      <Hero id={location} />
      <WhyWe />
      {location === 'long-distance-moving' && <AreasLongDistance />}
      <MovingEstimate />
      <About id={location} />
      <Services />
      {location === 'local-moving' && <Pricing />}
      <BookNow />
      <Counter />
      {location === 'local-moving' && <Areas />}
      <Reviews id={location} />
      <ContactUs />
    </Layout>
  );
};

export default PageTemplate;
