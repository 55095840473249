import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import './bookingForm.scss';

const BookingForm = ({ id }) => {
  const [token, setToken] = React.useState();
  const [step, setStep] = React.useState(1);
  const [totalPrice, setTotalPrice] = React.useState(0);
  const [order, setOrder] = React.useState({
    resource: id === 'long-distance-moving' ? 'Long Distance Moving ADS' : 'Local Moving ADS',
    // 'fields[customer_info][howfrom]': id === 'long-distance-moving' ? 'Long ADS' : 'Local ADS',
    // 'fields[foreman_info][status]': 'pending',
    // 'fields[state]': 'confirmed',
    // status: 'publish',
  });
  const [startDate, setStartDate] = React.useState(new Date());

  const [prices, setPrices] = React.useState({
    movers: 0,
    truckFee: 50,
    weekendFee: 0,
    paymentFee: 0,
    heavyItemsFee: 0,
    small_boxes: 0,
    medium_boxes: 0,
    wrapping_paper: 0,
  });

  const [orderRules, setOrderRules] = React.useState({
    truck: {
      '16feet': true,
      '20feet': true,
      '26feet': true,
    },
    movers: {
      '2movers': true,
    },
    time: {
      isWeekend: false,
    },
  });

  React.useEffect(() => {
    getAuthToken();
  }, []);

  React.useEffect(() => {
    switch (order['fields[customer_info][bedroom]']) {
      case 'Studio':
      case '1 bedroom':
        setOrderRules((prevState) => ({
          ...prevState,
          truck: {
            '16feet': true,
            '20feet': false,
            '26feet': false,
          },
          movers: {
            ...prevState.movers,
            '2movers': true,
          },
        }));
        break;
      case '2 bedroom':
        setOrderRules((prevState) => ({
          ...prevState,
          truck: {
            '16feet': false,
            '20feet': true,
            '26feet': true,
          },
          movers: {
            ...prevState.movers,
            '2movers': true,
          },
        }));
        break;
      case '3 bedroom':
        setOrderRules((prevState) => ({
          ...prevState,
          truck: {
            '16feet': false,
            '20feet': false,
            '26feet': true,
          },
          movers: {
            ...prevState.movers,
            '2movers': true,
          },
        }));
        break;
      case '4 bedroom':
      case '5 bedroom':
      case '6 bedroom':
        setOrderRules((prevState) => ({
          ...prevState,
          truck: {
            '16feet': false,
            '20feet': false,
            '26feet': true,
          },
          movers: {
            ...prevState.movers,
            '2movers': false,
          },
        }));
        break;
      default:
        setOrderRules((prevState) => ({
          ...prevState,
          truck: {
            '16feet': true,
            '20feet': true,
            '26feet': true,
          },
          movers: {
            '2movers': true,
          },
        }));
        break;
    }

    getPrice();
  }, [order]);

  React.useEffect(() => {
    const final_price =
      (prices.movers + prices.weekendFee + prices.paymentFee) * 3 +
      prices.truckFee +
      prices.heavyItemsFee +
      prices.small_boxes +
      prices.medium_boxes +
      prices.wrapping_paper;
    setTotalPrice(final_price);
  }, [prices]);

  const resetRules = () => {
    setOrderRules((prevState) => ({
      ...prevState,
      truck: {
        '16feet': true,
        '20feet': true,
        '26feet': true,
      },
      movers: {
        '2movers': true,
      },
      time: {
        isWeekend: false,
      },
    }));

    setPrices((prevState) => ({
      ...prevState,
      paymentFee: 0,
      weekendFee: 0,
      heavyItemsFee: 0,
      truckFee: 50,
      small_boxes: 0,
      medium_boxes: 0,
      wrapping_paper: 0,
    }));
  };

  const handleChange = (e) => {
    const key = e.target.name;
    const val = e.target.value;

    setOrder((prevState) => ({
      ...prevState,
      [key]: val,
    }));

    // if (
    //   key === 'fields[customer_info][medium_boxes]' ||
    //   key === 'fields[customer_info][small_boxes]' ||
    //   key === 'fields[customer_info][wrapping_paper]'
    // ) {
    //   setOrder((prevState) => ({
    //     ...prevState,
    //     [key]: Number(val),
    //   }));
    // } else {
    //   setOrder((prevState) => ({
    //     ...prevState,
    //     [key]: val,
    //   }));
    // }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    // const work = {
    //   ...order,
    //   ...(order['fields[customer_info][supplies]'] !== 'yes' && {
    //     'fields[customer_info][small_boxes]': '',
    //     'fields[customer_info][medium_boxes]': '',
    //     'fields[customer_info][wrapping_paper]': '',
    //   }),
    //   ...(order['fields[customer_info][truck]'] !== 'No truck just a labor' && {
    //     status: 'publish',
    //     'fields[state]': 'confirmed',
    //     'fields[customer_info][truck_fee]': '$50',
    //   }),
    //   ...(order['fields[customer_info][truck]'] === 'No truck just a labor' && {
    //     status: 'publish',
    //     'fields[state]': 'confirmed',
    //     'fields[customer_info][truck_fee]': 'No truck just a labor',
    //   }),
    //   ...(!order['fields[customer_info][packing]'] && {
    //     'fields[customer_info][packing]': 'No Packing',
    //   }),
    //   'fields[customer_info][result]': prices.movers + prices.weekendFee + prices.paymentFee,
    // };

    const work = {
      ...order,
      ...(!order.packing && {
        packing: 'No Packing',
      }),
      result: prices.movers + prices.weekendFee + prices.paymentFee,
    };

    // smart.people.move@gmail.com

    console.log(work);
    if (token) submitJob(work);
  };

  const getPrice = () => {
    // switch (order['fields[customer_info][movers]']) {
    switch (order.movers) {
      case '2 movers':
        setPrices((prevState) => ({
          ...prevState,
          movers: 110,
        }));
        break;
      case '3 movers':
        setPrices((prevState) => ({
          ...prevState,
          movers: 150,
        }));
        break;
      case '4 movers':
        setPrices((prevState) => ({
          ...prevState,
          movers: 190,
        }));
        break;
      case '5 movers':
        setPrices((prevState) => ({
          ...prevState,
          movers: 230,
        }));
        break;
      case '6 movers':
        setPrices((prevState) => ({
          ...prevState,
          movers: 270,
        }));
        break;
      default:
        setPrices((prevState) => ({
          ...prevState,
          movers: 0,
        }));
        break;
    }

    // switch (order['fields[customer_info][payment]']) {
    switch (order.payment) {
      case 'Venmo':
      case 'Zelle':
      case 'Credit card':
        setPrices((prevState) => ({
          ...prevState,
          paymentFee: 10,
        }));
        break;
      default:
        setPrices((prevState) => ({
          ...prevState,
          paymentFee: 0,
        }));
        break;
    }

    // switch (order['fields[customer_info][heavyItems]']) {
    switch (order.heavy_items) {
      case 'Piano':
      case 'Safe':
      case 'Other':
        setPrices((prevState) => ({
          ...prevState,
          heavyItemsFee: 300,
        }));
        break;
      default:
        setPrices((prevState) => ({
          ...prevState,
          heavyItemsFee: 0,
        }));
        break;
    }

    // if (order['fields[customer_info][small_boxes]'] > 0) {
    if (order.small_boxes > 0) {
      setPrices((prevState) => ({
        ...prevState,
        // small_boxes: order['fields[customer_info][small_boxes]'] * 3,
        small_boxes: order.small_boxes * 3,
      }));
    } else {
      setPrices((prevState) => ({
        ...prevState,
        small_boxes: 0,
      }));
    }

    // if (order['fields[customer_info][medium_boxes]'] > 0) {
    if (order.medium_boxes > 0) {
      setPrices((prevState) => ({
        ...prevState,
        // medium_boxes: order['fields[customer_info][medium_boxes]'] * 4,
        medium_boxes: order.medium_boxes * 4,
      }));
    } else {
      setPrices((prevState) => ({
        ...prevState,
        medium_boxes: 0,
      }));
    }

    // if (order['fields[customer_info][wrapping_paper]'] > 0) {
    if (order.wrapping_paper > 0) {
      setPrices((prevState) => ({
        ...prevState,
        // wrapping_paper: order['fields[customer_info][wrapping_paper]'] * 40,
        wrapping_paper: order.wrapping_paper * 40,
      }));
    } else {
      setPrices((prevState) => ({
        ...prevState,
        wrapping_paper: 0,
      }));
    }

    // if (order['fields[customer_info][supplies]'] === 'no') {
    if (order.supplies === 'no') {
      setPrices((prevState) => ({
        ...prevState,
        wrapping_paper: 0,
        medium_boxes: 0,
        small_boxes: 0,
      }));
    }
  };

  const goToNextStep = () => {
    setStep(step + 1);
  };

  const goToPrevStep = () => {
    setStep(step - 1);
  };

  // Async functions below
  const getAuthToken = async () => {
    try {
      const response = await axios.post('https://db.smartpeoplemoving.com/wp-json/jwt-auth/v1/token', {
        username: 'admin',
        password: 'Z7CX 9Lwd GRgB rHzT NOaf RIZr',
      });

      if (response.status === 200) {
        setToken(response.data.token);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitJob = async (job) => {
    const formData = new FormData();

    Object.entries(job).map((el) => formData.append(el[0], el[1]));

    try {
      const response = await axios.post(
        'https://db.smartpeoplemoving.com/wp-json/contact-form-7/v1/contact-forms/1709/feedback',
        formData,
      );

      if (response.status === 200) {
        toast.success(response.data.message);
        setOrder({
          resource: id === 'long-distance-moving' ? 'Long Distance Moving ADS' : 'Local Moving ADS',
        });
        setStep(1);
      }
      // const response = await axios.post('https://db.smartpeoplemoving.com/wp-json/wp/v2/works/', formData, {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // });
      // if (response.status === 201) {
      //   toast.success('Success');
      //   setOrder({
      //     resource: id === 'long-distance-moving' ? 'Long Distance Moving ADS' : 'Local Moving ADS',
      //     // 'fields[customer_info][howfrom]': id === 'long-distance-moving' ? 'Long ADS' : 'Local ADS',
      //     // 'fields[foreman_info][status]': 'pending',
      //     // 'fields[state]': 'confirmed',
      //     // status: 'publish',
      //   });
      //   setStep(1);
      // }
    } catch (error) {
      toast.error('Ooops! Something went wrong! Please, try again!');
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <form className="order-form">
        {step === 1 && (
          <Step1
            {...{
              handleChange,
              order,
              orderRules,
              resetRules,
              setOrderRules,
              setOrder,
              prices,
              setPrices,
              goToNextStep,
              startDate,
              setStartDate,
            }}
          />
        )}

        {step === 2 && <Step2 {...{ handleChange, goToNextStep, goToPrevStep, order }} />}

        {step === 3 && <Step3 {...{ handleChange, goToPrevStep, onSubmit, order, totalPrice }} />}

        {/* <InputMask mask="99/99/9999">
          {() => <input name="date" type="text" placeholder="Preferred Moving Date" />}
        </InputMask> */}
      </form>
    </React.Fragment>
  );
};

export default BookingForm;

const Step1 = ({
  handleChange,
  order,
  orderRules,
  resetRules,
  setOrderRules,
  setOrder,
  prices,
  setPrices,
  goToNextStep,
  startDate,
  setStartDate,
}) => {
  const dateFormat = (date) => {
    const year = new Date(date).getFullYear();
    const month = new Date(date).getMonth() + 1;
    const day = new Date(date).getDate();
    const isWeekend = new Date(date).getDay() === 0 || new Date(date).getDay() === 5 || new Date(date).getDay() === 6;

    // const formattedDate = `${year}${String(month).length > 1 ? month : `0${month}`}${
    //   String(day).length > 1 ? day : `0${day}`
    // }`;

    const formattedDate = `${month}/${day}/${year}`;

    resetRules();

    if (isWeekend) {
      setOrderRules((prevState) => ({
        ...prevState,
        time: {
          isWeekend: true,
        },
      }));
      setPrices((prevState) => ({
        ...prevState,
        weekendFee: 10,
      }));
    }

    setStartDate(date);

    setOrder((prevState) => ({
      ...prevState,
      date: formattedDate,
      // 'fields[date]': Number(formattedDate),
    }));
  };

  return (
    <div className="order-form__step1">
      <div className="input-wrapper">
        <select
          required
          // name="fields[customer_info][bedroom]"
          // value={order['fields[customer_info][bedroom]']}
          name="moving_size"
          value={order.moving_size}
          onChange={handleChange}
        >
          <option hidden>Moving Size</option>
          <option disabled>Moving Size</option>
          <option value="Studio">Studio</option>
          <option value="1 bedroom">1 bedroom</option>
          <option value="2 bedroom">2 bedroom</option>
          <option value="3 bedroom">3 bedroom</option>
          <option value="4 bedroom">4 bedroom</option>
          <option value="5 bedroom">5 bedroom</option>
          <option value="6 bedroom">6 bedroom</option>
        </select>
      </div>

      <div className="input-wrapper">
        <select
          required
          // name="fields[customer_info][truck]"
          // value={order['fields[customer_info][truck]']}
          name="truck"
          value={order.truck}
          onChange={handleChange}
          // disabled={!order['fields[customer_info][bedroom]']}
          disabled={!order.moving_size}
        >
          <option hidden>Truck size</option>
          <option disabled>Truck size</option>
          <option value="No truck just a labor">No truck just a labor</option>
          <option value="16 feet" disabled={!orderRules.truck['16feet']}>
            16 feet
          </option>
          <option value="20 feet" disabled={!orderRules.truck['20feet']}>
            20 feet
          </option>
          <option value="26 feet" disabled={!orderRules.truck['26feet']}>
            26 feet
          </option>
        </select>
      </div>

      <div className="input-wrapper">
        <select
          required
          // name="fields[customer_info][movers]"
          // value={order['fields[customer_info][movers]']}
          name="movers"
          value={order.movers}
          onChange={handleChange}
          // disabled={!order['fields[customer_info][truck]']}
          disabled={!order.truck}
        >
          <option hidden>Crew size</option>
          <option disabled>Crew size</option>
          <option value="2 movers" disabled={!orderRules.movers['2movers']}>
            2 movers
          </option>
          <option value="3 movers">3 movers</option>
          <option value="4 movers">4 movers</option>
          <option value="5 movers">5 movers</option>
          <option value="6 movers">6 movers</option>
        </select>
      </div>

      <div className="input-wrapper">
        <DatePicker
          selected={startDate}
          onChange={dateFormat}
          minDate={new Date()}
          dateFormat="MM/dd/yyyy"
          // disabled={!order['fields[customer_info][movers]']}
          disabled={!order.movers}
        />
      </div>

      <div className="input-wrapper">
        <select
          required
          // name="fields[customer_info][time]"
          // value={order['fields[customer_info][time]']}
          name="time"
          value={order.time}
          onChange={handleChange}
          // disabled={!order['fields[date]']}
          disabled={!order.date}
        >
          <option hidden>Start time</option>
          <option disabled>Start time</option>
          <option value="08:00">8 am</option>
          <option value="09:00">9 am</option>
          <option value="10:00">10 am</option>
          <option value="11:00" disabled={orderRules.time.isWeekend}>
            11 am
          </option>
          <option value="12:00" disabled={orderRules.time.isWeekend}>
            12 pm
          </option>
          <option value="14:00">2-4 pm(start time frame)</option>
        </select>
      </div>

      <div className="input-wrapper">
        <select
          required
          // name="fields[customer_info][payment]"
          // value={order['fields[customer_info][payment]']}
          name="payment"
          value={order.payment}
          onChange={handleChange}
          // disabled={!order['fields[customer_info][time]']}
          disabled={!order.time}
        >
          <option hidden>Payment</option>
          <option disabled>Payment</option>
          <option value="Cash">
            Cash {orderRules.time.isWeekend ? `$${Number(prices.movers) + 10}/h` : `$${prices.movers}/h`}
          </option>
          <option value="Credit card">
            Credit card{' '}
            {orderRules.time.isWeekend ? `$${Number(prices.movers) + 20}/h` : `$${Number(prices.movers) + 10}/h`}
          </option>
          <option value="Venmo">
            Venmo {orderRules.time.isWeekend ? `$${Number(prices.movers) + 20}/h` : `$${Number(prices.movers) + 10}/h`}
          </option>
          <option value="Zelle">
            Zelle {orderRules.time.isWeekend ? `$${Number(prices.movers) + 20}/h` : `$${Number(prices.movers) + 10}/h`}
          </option>
        </select>
      </div>

      <div className="input-wrapper">
        <select
          required
          // name="fields[customer_info][typeofresidency]"
          // value={order['fields[customer_info][typeofresidency]']}
          name="type_of_residency"
          value={order.type_of_residency}
          onChange={handleChange}
          // disabled={!order['fields[customer_info][payment]']}
          disabled={!order.payment}
        >
          <option hidden>Type of residency</option>
          <option disabled>Type of residency</option>
          <option value="Apartment">Apartment</option>
          <option value="House">House</option>
          <option value="Townhouse">Townhouse</option>
          <option value="Storage">Storage</option>
          <option value="Office">Office</option>
          <option value="Other">Other</option>
        </select>
      </div>

      <div className="input-wrapper">
        <select
          required
          // name="fields[customer_info][packing]"
          // value={order['fields[customer_info][packing]']}
          name="packing"
          value={order.packing}
          onChange={handleChange}
          // disabled={!order['fields[customer_info][typeofresidency]']}
          disabled={!order.type_of_residency}
        >
          <option value="No Packing">No Packing</option>
          <option value="Partial Packing">Partial packing (included into the rate)</option>
          <option value="Full Packing">
            Full packing (included into the rate; boxes wrapping paper - additional charge)
          </option>
        </select>
      </div>

      <div className="input-wrapper">
        <select
          required
          // name="fields[customer_info][supplies]"
          // value={order['fields[customer_info][supplies]']}
          name="supplies"
          value={order.supplies}
          onChange={handleChange}
          // disabled={!order['fields[customer_info][typeofresidency]']}
          disabled={!order.type_of_residency}
        >
          <option hidden>Do you need supplies?</option>
          <option disabled>Do you need supplies?</option>
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
      </div>

      <div className="input-wrapper">
        <select
          required
          // name="fields[customer_info][heavyItems]"
          // value={order['fields[customer_info][heavyItems]']}
          name="heavy_items"
          value={order.heavy_items}
          onChange={handleChange}
          // disabled={!order['fields[customer_info][supplies]']}
          disabled={!order.supplies}
        >
          <option hidden>Do you have Items more then 250lb?</option>
          <option disabled>Do you have Items more then 250lb?</option>
          <option value="Piano">Piano</option>
          <option value="Safe">Safe</option>
          <option value="Other">Other</option>
          <option value="No">No</option>
        </select>
      </div>

      {/* {order['fields[customer_info][supplies]'] === 'yes' && ( */}
      {order.supplies === 'yes' && (
        <React.Fragment>
          <p>Supplies</p>

          <div className="addition-line">
            <div>
              <span>Small Box</span>
              <input
                id="small-boxes"
                type="number"
                // name="fields[customer_info][small_boxes]"
                // value={order['fields[customer_info][small_boxes]'] || ''}
                name="small_boxes"
                value={order.small_boxes || ''}
                placeholder="0"
                onChange={handleChange}
              />
              <span>$3</span>
            </div>

            <div>
              <span>Medium boxes</span>
              <input
                id="medium-boxes"
                type="number"
                // name="fields[customer_info][medium_boxes]"
                // value={order['fields[customer_info][medium_boxes]'] || ''}
                name="medium_boxes"
                value={order.medium_boxes || ''}
                placeholder="0"
                onChange={handleChange}
              />
              <span>$4</span>
            </div>
            <div>
              <span>Wrapping paper</span>
              <input
                id="wrapping-paper"
                type="number"
                // name="fields[customer_info][wrapping_paper]"
                // value={order['fields[customer_info][wrapping_paper]'] || ''}
                name="wrapping_paper"
                value={order.wrapping_paper || ''}
                placeholder="0"
                onChange={handleChange}
              />
              <span>$40</span>
            </div>
          </div>
        </React.Fragment>
      )}

      <div className="order-form__buttons">
        <button
          onClick={goToNextStep}
          disabled={!order.heavy_items}
          // disabled={!order['fields[customer_info][heavyItems]']}
          className="nextButton"
        >
          Next
        </button>
      </div>
    </div>
  );
};

const Step2 = ({ handleChange, goToNextStep, goToPrevStep, order }) => (
  <div className="order-form__step2">
    <input
      required
      type="text"
      // name="fields[customer_info][pickup_address][0][full_address]"
      name="pickup_address"
      placeholder="Pick-up Address"
      onChange={handleChange}
      // value={order['fields[customer_info][pickup_address][0][full_address]'] || ''}
      value={order.pickup_address || ''}
    />

    <input
      type="number"
      // name="fields[customer_info][pickup_address][0][unit]"
      name="pickup_unit"
      placeholder="Unit"
      onChange={handleChange}
      // value={order['fields[customer_info][pickup_address][0][unit]'] || ''}
      value={order.pickup_unit || ''}
    />

    <input
      required
      type="text"
      // name="fields[customer_info][dropoff_address][0][full_address]"
      name="dropoff_address"
      placeholder="Drop-off Address"
      // disabled={!order['fields[customer_info][pickup_address][0][full_address]']}
      disabled={!order.pickup_address}
      onChange={handleChange}
      // value={order['fields[customer_info][dropoff_address][0][full_address]'] || ''}
      value={order.dropoff_address || ''}
    />

    <input
      type="number"
      // name="fields[customer_info][dropoff_address][0][unit]"
      name="dropoff_unit"
      placeholder="Unit"
      // disabled={!order['fields[customer_info][pickup_address][0][full_address]']}
      disabled={!order.pickup_address}
      onChange={handleChange}
      // value={order['fields[customer_info][dropoff_address][0][unit]'] || ''}
      value={order.dropoff_unit || ''}
    />

    <div className="order-form__buttons">
      <button onClick={goToPrevStep} className="prevButton">
        Prev
      </button>
      <button
        onClick={goToNextStep}
        className="nextButton"
        // disabled={!order['fields[customer_info][dropoff_address][0][full_address]']}
        disabled={!order.dropoff_address}
      >
        Next
      </button>
    </div>
  </div>
);

const Step3 = ({ order, handleChange, goToPrevStep, totalPrice, onSubmit }) => (
  <React.Fragment>
    <div className="order-form__step3">
      <div className="full-width">Customer information</div>

      <div className="full-width">
        <input
          id="customer_name"
          required
          type="text"
          // name="fields[customer_info][customer_name]"
          // value={order['fields[customer_info][customer_name]'] || ''}
          name="customer_name"
          value={order.customer_name || ''}
          placeholder="Full name"
          onChange={handleChange}
        />
      </div>

      <div className="half-width">
        <input
          required
          id="inputPhone"
          type="text"
          // name="fields[customer_info][customer_phone]"
          // value={order['fields[customer_info][customer_phone]'] || ''}
          name="customer_phone"
          value={order.customer_phone || ''}
          placeholder="Phone"
          onChange={handleChange}
          // disabled={!order['fields[customer_info][customer_name]']}
          disabled={!order.customer_name}
        />
      </div>

      <div className="half-width">
        <input
          required
          id="inputMail"
          type="email"
          // name="fields[customer_info][customer_email]"
          // value={order['fields[customer_info][customer_email]'] || ''}
          name="customer_email"
          value={order.customer_email || ''}
          placeholder="Email"
          onChange={handleChange}
          // disabled={!order['fields[customer_info][customer_phone]']}
          disabled={!order.customer_phone}
        />
      </div>

      <div className="full-width">Contact person information (optional)</div>

      <div className="full-width">
        <input
          type="text"
          // name="fields[customer_info][contact_name]"
          // value={order['fields[customer_info][contact_name]'] || ''}
          name="contact_name"
          value={order.contact_name || ''}
          placeholder="Full name"
          onChange={handleChange}
        />
      </div>

      <div className="half-width">
        <input
          type="number"
          // name="fields[customer_info][contact_phone]"
          // value={order['fields[customer_info][contact_phone]'] || ''}
          name="contact_phone"
          value={order.contact_phone || ''}
          placeholder="Phone"
          onChange={handleChange}
        />
      </div>

      <div className="half-width">
        <input
          type="email"
          // name="fields[customer_info][contact_email]"
          // value={order['fields[customer_info][contact_email]'] || ''}
          name="contact_email"
          order={order.contact_email}
          placeholder="Email"
          onChange={handleChange}
        />
      </div>

      <div className="full-width">
        <textarea
          rows="4"
          // name="fields[customer_info][note]"
          // value={order['fields[customer_info][note]'] || ''}
          name="note"
          value={order.note || ''}
          placeholder="Additional notes from customer (boxes/wrapping paper/bubble wrap/floor protection/items more than 250lb)"
          onChange={handleChange}
        />
      </div>
    </div>

    <div className="order-form__buttons">
      <button onClick={goToPrevStep} className="prevButton">
        Prev
      </button>

      <button
        onClick={onSubmit}
        className="gradient-btn truck"
        // disabled={!order['fields[customer_info][customer_email]']}
        disabled={!order.customer_email}
      >
        Request a quote
      </button>
    </div>

    <p className="order-form__caption">
      By clicking on "Request a Quote", I agree to being contacted by text, SMS, email, or phone, including an automated
      dialing system or pre-recorded voice in connection with my upcoming move from or on behalf of Smart People Moving.
      Message and data rates may apply.
    </p>
  </React.Fragment>
);
