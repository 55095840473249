import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import PageTemplate from './pages/PageTemplate';
import Booking from './pages/Booking';
import PrivacyPolicy from './pages/PrivacyPolicy';
import AccessibilityStatement from './pages/AccessibilityStatement';
import TermsOfUse from './pages/TermsOfUse';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate replace to="/local-moving" />} />

        <Route path="/local-moving" element={<PageTemplate />} />
        <Route path="/long-distance-moving" element={<PageTemplate />} />

        <Route path="/book-now" element={<Booking />} />

        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/accessibility-statement" element={<AccessibilityStatement />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />

        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
