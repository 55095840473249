import { NavLink } from 'react-router-dom';

import './pricing.scss';

const offers = [
  {
    movers: 2,
    rate: 110,
  },
  {
    movers: 3,
    rate: 150,
  },
  {
    movers: 4,
    rate: 190,
  },
];

const Pricing = () => (
  <section className="pricing">
    <div className="container">
      <h2 className="section-title">
        Special <span>Pricing</span>
      </h2>

      <div className="pricing__list">
        {offers.map((el) => (
          <PricingCard key={el.rate} data={el} />
        ))}
      </div>
    </div>
  </section>
);

export default Pricing;

const PricingCard = ({ data }) => (
  <div className="pricing__list--card">
    <span className="pricing__list--card__movers">{data.movers} Movers</span>
    <span>From</span>
    <span className="pricing__list--card__rate">
      ${data.rate}
      <span>/hr</span>
    </span>
    <p>
      +70$ Tuck fee
      <br />
      Items more then 300lb + additional $250
      <br />3 or more flights of stairs + additional $5/flight during we use the stairs
    </p>
    <ul>
      <li>Free use of Blankets & Pads</li>
      <li>Free use of Wardrobe Boxes</li>
      <li>FREE shrink wrap and tape!</li>
      <li>Assembly / Reassembly</li>
      <li>Packing / Unpacking</li>
      <li>Tax & Basic Insurance</li>
      <li>Included Dollies, Tools & Straps</li>
    </ul>

    <NavLink to="/book-now" className="gradient-btn" state={{ prevLocation: window.location.pathname.split('/')[1] }}>
      Order Now
    </NavLink>
  </div>
);
