import React from 'react';
import { useLocation } from 'react-router-dom';

import { Layout, Seo, BookingForm } from '../components';

const Booking = () => {
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Layout pageId="booking-page">
      <Seo
        data={{
          title: 'Book Now!',
          description: '',
        }}
      />

      <section className="book-now">
        <div className="container">
          <h2 className="section-title">
            <span>Book Now!</span>
          </h2>
          <div className="form-wrapper">
            <BookingForm id={location.state.prevLocation} />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Booking;
