import './about.scss';

import { local_moving, long_moving } from '../../static/content';

const About = ({ id }) => (
  <section className="about">
    <div className="container">
      <h2 className="section-title">
        <span>Top Rated</span>
        <br />
        San Francisco
        <br />
        Moving Company
      </h2>

      <div className="about__content">
        <div
          className="about__content--text"
          dangerouslySetInnerHTML={{ __html: id === 'long-distance-moving' ? long_moving : local_moving }}
        />
        <div className="about__content--awards" />
      </div>
    </div>
  </section>
);

export default About;
