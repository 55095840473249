import './services.scss';

const services = [
  {
    icon: '/assets/images/services/1.svg',
    title: 'Local Moving',
  },
  {
    icon: '/assets/images/services/2.svg',
    title: 'Residential Moving',
  },
  {
    icon: '/assets/images/services/3.svg',
    title: 'Commercial Moving',
  },
  {
    icon: '/assets/images/services/4.svg',
    title: 'VIP Moving',
  },
  {
    icon: '/assets/images/services/5.svg',
    title: 'Heavy Items Moving',
  },
  {
    icon: '/assets/images/services/6.svg',
    title: 'Packing Services',
  },
];

const Services = () => (
  <section className="services">
    <div className="container">
      <h2 className="section-title white">
        Moving <span>Services</span>
      </h2>

      <div className="services__grid">
        {services.map((el, index) => (
          <ServicesCard {...{ el, key: index }} />
        ))}
      </div>
    </div>
  </section>
);

export default Services;

const ServicesCard = ({ el }) => (
  <div className="services__grid--item">
    <div className="services__grid--item__icon">
      <img src={el.icon} alt={el.title} />
    </div>
    <p>{el.title}</p>
  </div>
);
